<template>
  <en-drawer title="返修工单" :model-value="modelValue" @close="$emit('update:model-value', false)" :size="1200">
    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :loading="table.loading" pagination :paging="table.paging" :height="height">
          <en-table-column label="操作" width="80">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
              <en-button
                v-if="row.status?.code === 'PD'"
                :type="row.status?.code === 'PD' ? 'primary' : 'danger'"
                link
                @click="table.select.click(row)"
              >
                {{ row.status?.code === 'PD' ? '选择' : '请先结算' }}
              </en-button>
            </template>
          </en-table-column>
          <en-table-column label="工单状态" prop="status.message"></en-table-column>
          <en-table-column label="工单号">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">
              <en-button type="primary" link @click="table.serialNo.click(row)">{{ row.serialNo }}</en-button>
            </template>
          </en-table-column>
          <en-table-column label="服务顾问" prop="advisor.name"></en-table-column>
          <en-table-column label="工单合计">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">{{ formatMoney(table.totalAmount.value(row)) }}</template>
          </en-table-column>
          <en-table-column label="进厂时间" prop="enterDatetime">
            <template #default="{ row }: { row: EnocloudServiceDefinitions['ServiceDto'] }">{{ formatDate(row.enterDatetime) }}</template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

export const summary = (row: EnocloudServiceDefinitions['ServiceDto']) => {
  const {
    maintenanceAmount,
    goodsAmount,
    maintenanceAmountAfterDiscount,
    managementFee,
    goodsAmountAfterDiscount,
    otherCosts,
    couponInstancesAmount,
    autoEraseAmount,
    discount
  } = row

  const otherFee = otherCosts?.reduce((fee, item) => calculator.add(fee, calculator.mul(item.count, item.price)), 0)

  const maintenanceDiscount = calculator.sub(maintenanceAmount, maintenanceAmountAfterDiscount)

  const goodsDiscount = calculator.sub(goodsAmount, goodsAmountAfterDiscount)

  const serveiceFee = calculator.add(calculator.add(calculator.add(maintenanceAmount, goodsAmount), managementFee), otherFee)

  const totalAmount = calculator.sub(
    calculator.sub(calculator.sub(calculator.sub(calculator.sub(serveiceFee, maintenanceDiscount), goodsDiscount), discount), couponInstancesAmount),
    autoEraseAmount
  )

  return {
    totalAmount
  }
}

export default factory({
  props: {
    modelValue: Boolean,
    vehicleId: Number as PropType<number>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    select: (value: EnocloudServiceDefinitions['ServiceDto']) => true
  },

  watch: {
    modelValue: {
      handler(visible) {
        if (visible) this.table.get()
      }
    }
  },

  config: {
    children: {
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/service/complex',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { vehicleId: this.vehicleId }
            },
            convert(data: EnocloudServiceDefinitions['ServiceDto'][]) {
              return data
                .map((item) => {
                  item.maintenances = item.maintenances?.filter((m) => m.warrantied?.value) ?? []
                  return item
                })
                .filter((item) => Boolean(item.maintenances?.length))
            }
          }
        },
        children: {
          totalAmount: {
            value(row: EnocloudServiceDefinitions['ServiceDto']) {
              return summary(row).totalAmount
            }
          },
          select: {
            click(row: EnocloudServiceDefinitions['ServiceDto']) {
              this.emit('select', row)
            }
          },
          serialNo: {
            click(row: EnocloudServiceDefinitions['ServiceDto']) {
              this.emit('update:model-value', false)
              this.router.push('/service/business/workorder', (vm) => {
                vm.form.init()
                vm.form.data.id = row.id
                vm.form.get()
              })
            }
          }
        }
      },
      footer: {
        children: {
          cancel: {
            click() {
              this.emit('update:model-value', false)
            }
          },
          confirm: {
            click() {}
          }
        }
      }
    }
  }
})
</script>
